import React from "react";
import BlogCommentForm from "../../components/BlogDetails/BlogCommentForm";
import BlogComments from "../../components/BlogDetails/BlogComments";
import BlogDetails from "../../components/BlogDetails/BlogDetails";
import LayoutBlog from "../../layouts/LayoutBlog";

const Pricing = () => {
  return (
    <LayoutBlog pageTitle={"Pricing"} item={"PRICING"}>
        <section className="pricing py-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 mb-4">
                        <div className="card mb-5 mb-lg-0">
                            <div className="card-body">
                                <h5 className="card-title text-muted text-uppercase text-center">Plan A</h5>
                                <hr/>
                                    <ul className="fa-ul">
                                        <li><span className="fa-li"><i className="fas fa-check"></i></span>
                                            Staking Amount; $1,000 - $4,999
                                        </li>
                                        <li><span className="fa-li"><i className="fas fa-check"></i></span>
                                            Reward Frequency: weekly
                                        </li>
                                        <li><span className="fa-li"><i className="fas fa-check"></i></span>
                                            Profit rate; 5%
                                        </li>
                                        <li><span className="fa-li"><i className="fas fa-check"></i></span>
                                            Referral bonus; 5%
                                        </li>
                                        <li className="text-muted"><span className="fa-li"><i
                                            className="fas fa-check"></i></span>Unlimited
                                            Live Trading with Experts
                                        </li>
                                        <li className="text-muted"><span className="fa-li"><i
                                            className="fas fa-check"></i></span>Dedicated
                                            Phone Support
                                        </li>
                                        <li className="text-muted"><span className="fa-li"><i
                                            className="fas fa-check"></i></span>SMS & Email Alerts
                                        </li>
                                        <li className="text-muted"><span className="fa-li"><i
                                            className="fas fa-check"></i></span>Professional Charts
                                        </li>
                                    </ul>
                                    <div className="d-grid">
                                        <a href="https://portal.swift-minepro.com" className="btn btn-primary text-uppercase">Get Started</a>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4">
                        <div className="card mb-5 mb-lg-0">
                            <div className="card-body">
                                <h5 className="card-title text-muted text-uppercase text-center">Plan B</h5>
                                <hr/>
                                <ul className="fa-ul">
                                    <li><span className="fa-li"><i className="fas fa-check"></i></span>
                                        Staking Amount; $5,000 - $19,999
                                    </li>
                                    <li><span className="fa-li"><i className="fas fa-check"></i></span>
                                        Reward Frequency: weekly
                                    </li>
                                    <li><span className="fa-li"><i className="fas fa-check"></i></span>
                                        Profit rate; 11%
                                    </li>
                                    <li><span className="fa-li"><i className="fas fa-check"></i></span>
                                        Referral bonus; 7%
                                    </li>
                                    <li className="text-muted"><span className="fa-li"><i
                                        className="fas fa-check"></i></span>Unlimited
                                        Live Trading with Experts
                                    </li>
                                    <li className="text-muted"><span className="fa-li"><i
                                        className="fas fa-check"></i></span>Dedicated
                                        Phone Support
                                    </li>
                                    <li className="text-muted"><span className="fa-li"><i
                                        className="fas fa-check"></i></span>SMS & Email Alerts
                                    </li>
                                    <li className="text-muted"><span className="fa-li"><i
                                        className="fas fa-check"></i></span>Professional Charts
                                    </li>
                                </ul>
                                <div className="d-grid">
                                    <a href="https://portal.swift-minepro.com" className="btn btn-primary text-uppercase">Get Started</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title text-muted text-uppercase text-center">Plan C</h5>
                                <hr/>
                                <ul className="fa-ul">
                                    <li><span className="fa-li"><i className="fas fa-check"></i></span>
                                        Staking Amount; $20,000 - $99,999
                                    </li>
                                    <li><span className="fa-li"><i className="fas fa-check"></i></span>
                                        Reward Frequency: weekly
                                    </li>
                                    <li><span className="fa-li"><i className="fas fa-check"></i></span>
                                        Profit rate; 18%
                                    </li>
                                    <li><span className="fa-li"><i className="fas fa-check"></i></span>
                                        Referral bonus; 10%
                                    </li>
                                    <li className="text-muted"><span className="fa-li"><i
                                        className="fas fa-check"></i></span>Unlimited
                                        Live Trading with Experts
                                    </li>
                                    <li className="text-muted"><span className="fa-li"><i
                                        className="fas fa-check"></i></span>Dedicated
                                        Phone Support
                                    </li>
                                    <li className="text-muted"><span className="fa-li"><i
                                        className="fas fa-check"></i></span>SMS & Email Alerts
                                    </li>
                                    <li className="text-muted"><span className="fa-li"><i
                                        className="fas fa-check"></i></span>Professional Charts
                                    </li>
                                </ul>
                                <div className="d-grid">
                                    <a href="https://portal.swift-minepro.com" className="btn btn-primary text-uppercase">Get Started</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title text-muted text-uppercase text-center">Plan D</h5>
                                 <hr/>
                                <ul className="fa-ul">
                                    <li><span className="fa-li"><i className="fas fa-check"></i></span>
                                        Staking Amount; $100,000 or more
                                    </li>
                                    <li><span className="fa-li"><i className="fas fa-check"></i></span>
                                        Reward Frequency: weekly
                                    </li>
                                    <li><span className="fa-li"><i className="fas fa-check"></i></span>
                                        Profit rate; 25%
                                    </li>
                                    <li><span className="fa-li"><i className="fas fa-check"></i></span>
                                        Referral bonus; 15%
                                    </li>
                                    <li className="text-muted"><span className="fa-li"><i
                                        className="fas fa-check"></i></span>Unlimited
                                        Live Trading with Experts
                                    </li>
                                    <li className="text-muted"><span className="fa-li"><i
                                        className="fas fa-check"></i></span>Dedicated
                                        Phone Support
                                    </li>
                                    <li className="text-muted"><span className="fa-li"><i
                                        className="fas fa-check"></i></span>SMS & Email Alerts
                                    </li>
                                    <li className="text-muted"><span className="fa-li"><i
                                        className="fas fa-check"></i></span>Professional Charts
                                    </li>
                                </ul>
                                <div className="d-grid">
                                    <a href="https://portal.swift-minepro.com" className="btn btn-primary text-uppercase">Get Started</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </LayoutBlog>
  );
};

export default Pricing;
