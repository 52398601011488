import React from "react";
import { Link } from "react-router-dom";

const BlogCategories = () => {
  const categories = [
    { title: "CBD", quantity: "", url: "/services" },
    { title: "Gold", quantity: "", url: "/services" },
    { title: "Oil", quantity: "", url: "/services" },
    { title: "Mining", quantity: "", url: "/services" },
    { title: "Silver Futures", quantity: "", url: "/services" },
    { title: "Loan", quantity: "", url: "/services" },
    { title: "NFT", quantity: "", url: "/services" },
    { title: "EFTs", quantity: "", url: "/services" },
    { title: "Safe Heaven Assets", quantity: "", url: "/services" },
    { title: "Preservation of Wealth", quantity: "", url: "/services" },
    { title: "Retirement Accounts", quantity: "", url: "/services" },
    { title: "Students Insurance", quantity: "", url: "/services" },
    { title: "Digital Asset Management", quantity: "", url: "/services" },
  ];

  return (
    <div className="blog-widget">
      <h2 className="bw-title">Commodities List</h2>
      <div className="category-list">
        <ul>
          {categories.map((category, index) => (
            <li key={index}>
              <Link to={category.url}>
                {category.title} <span>{category.quantity}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BlogCategories;
