import React from "react";
import Countdown from "react-countdown";

const CountDownOne = () => {
  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <>
          <div className="time-count day">
            <span>{"00"}</span>Days
          </div>
          <div className="time-count hour">
            <span>{"00"}</span>hour
          </div>
          <div className="time-count min">
            <span>{"00"}</span>minute
          </div>
          <div className="time-count sec">
            <span>{"00"}</span>second
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="time-count day">
            <span>45+</span>Companies
          </div>
          <div className="time-count hour">
            <span>100+</span>Individuals
          </div>
          <div className="time-count min">
            <span>5000+</span>Trades
          </div>
          <div className="time-count ">
            <span>350+</span>Portfolio
          </div>
        </>
      );
    }
  };

  return (
    <div className="coming-time">
      <Countdown date={Date.now() + 5000000} renderer={renderer} autoStart={false}  />
    </div>
  );
};

export default CountDownOne;
