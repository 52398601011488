import React from "react";
import BlogListItem from "./BlogListItem";
import img01 from "../../assets/img/services/bitcoin-2021-09-02-03-14-41-utc.jpg";
import img02 from "../../assets/img/services/stock-trading-graph-price-prediction-and-profit-ga-2022-02-02-03-57-45-utc.jpg";
import img03 from "../../assets/img/services/wealth-to-health-inscription-with-dollar-bills-2022-01-28-17-29-59-utc.JPG";
import img04 from "../../assets/img/services/money-and-gold-gold-bullions-financial-concept-2021-08-29-01-16-19-utc.jpg";

const BlogList = () => {
  const blog_list = [
    {
      url: "https://portal.swift-minepro.com/login",
      src: img01,
      author: "Alextina",
      time: "Dec 28, 2022",
      comments: "03",
      title:
        "Digital Assets/Crypto Investment and Management",
      summary:
        "We help you grow your wealth through proper crypto investment strategies. Do you know that" +
          "Cryptocurrency creates thousands of millionaires every year? The key to that is proper strategy",
    },
    {
      url: "https://portal.swift-minepro.com/login",
      src: img02,
      author: "Alextina",
      time: "Dec 28, 2022",
      comments: "03",
      title: "Stock Trading and Management",
      summary:
        "Select US stocks are considered safe haven and there a lots of growth sensitive assets. " +
          "This is not only limited to the US. Join us, let us guide you to making your first ten million via stock trading.",
    },
    {
      url: "https://portal.swift-minepro.com/login",
      src: img03,
      author: "Alextina",
      time: "Dec 28, 2022",
      comments: "03",
      title: `Wealth Management`,
      summary:
        "A lot of people are wealthy today and bankrupt tomorrow. Don't let that be you." +
          "Let us help you grow instead, so that you will never be less than you were yesterday." +
          "Let our seasoned professionals guides you as you grow your wealth.",
    },
    {
      url: "https://portal.swift-minepro.com/login",
      src: img04,
      author: "Alextina",
      time: "Dec 28, 2022",
      comments: "03",
      title: `Gold/Silver, Futures Trading`,
      summary:
          " At Crypto.com we deliver investment and trading services using" +
          " 10 markets trading platforms. Some of our trading services are: contracts" +
          " for difference (CFD), precious metals trading, futures (agriculture, commodities," +
          " energy, etc.) and indices (stock index) trading. ",
    },
  ];

  return (
    <>
      {blog_list.map((blog, index) => (
        <BlogListItem key={index} item={blog} />
      ))}
    </>
  );
};

export default BlogList;
