import React from "react";
import img01 from "../../assets/img/about_img01.png";
import img02 from "../../assets/img/about_img02.png";
import { Link } from "react-router-dom";

const WhoWeAre = (props) => {
  return (
    <section id="about" className="about-area pt-130 pb-130">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              <img src={img01} alt="" />
              <img src={img02} alt="" className="img-two" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <span className="sub-title">Who we are</span>
                <h2 className="title">
                  The World’s <span>leading DIGITAL ASSETS</span> Management Platform That Offers Rewards
                </h2>
              </div>
              <p>
                We're investing in different asset classes that are not in traditional benchmarks. A good example is what are called busted convertible bonds, which are bonds whose underlying stock trades well below the price at which the bond would convert to stock.

                Having the ability to invest in a wide variety of assets lets you better balance the income you get from bonds and dividends against the amount of risk you're taking to earn that income.”

              </p>
              <Link to="https://portal.swift-minepro.com/login" className="btn">
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
