import React from "react";
import { Link } from "react-router-dom";
import docImg from "../../assets/img/document_img.png";
import doc from "../../assets/pdf/SwiftMineProApp.apk"

const WhitePaper = () => {
  return (
    <section className="document-area pt-60">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 order-2 order-lg-0">
            <div
              className="document-img text-center wow fadeInUp"
              data-wow-delay=".2s"
            >
              <img src={docImg} alt="" />
            </div>
          </div>

          <div className="col-lg-5 col-md-7">
            <div
              className="document-content mt-50 wow fadeInRight"
              data-wow-delay=".2s"
            >
              <div className="section-title mb-35">
                <span className="sub-title">Mobile App</span>
                <h2 className="title">
                  Swift Mine Pro <span>App</span>
                </h2>
              </div>

              <ul className="document-list">
                <li>Who we are</li>
                <li>What we do</li>
                <li>Why choose us</li>
                <li>Our Plans</li>
              </ul>

              <a href={doc} target="_blank" className="btn">
                Download
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhitePaper;
