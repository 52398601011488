import React from "react";
import blogImg from "../../assets/img/blog_img01.jpg";
import blogDetails01 from "../../assets/img/blog_details01.jpg";
import blogDetails02 from "../../assets/img/blog_details02.jpg";
import blogDetails03 from "../../assets/img/blog_details03.jpg";
import { Link } from "react-router-dom";
import TeamOne from "../Team/TeamOne";

const BlogDetails = () => {
  return (
    <div className="blog-post-item blog-details-wrap">
      <div className="blog-post-thumb">
        <img src={blogImg} alt="" />
      </div>
      <div className="blog-post-content">

        <h2 className="title">
         Who We Are
        </h2>
        <p>
          Swift Mine Pro is a trade, investment, assets and portfolio management
          firm with 12+ years of providing sophisticated and innovative financial solutions.
          Our diverse team of well seasoned professionals have provided exceptional service to 45+
          corporate clients and thousands of private individuals. We have raised the wealth of
          thousands of clients, who till tomorrow, trust us to manage their assets. <br/>
          Swift Mine Pro Investments is licensed by the International
          Financial Services Commission (IFSC) and is one of the largest brokers worldwide. We operate
          on more than 50 markets globally and provide high-quality financial services to customers
          from America, Europe, Asia and the Middle East. Our clients range from individuals to
          corporate entities, including hedge funds and institutional investors. Swift Mine Pro and our
          team of 252 experts in various department of the platform has helped most of the active
          investors in the digital currency industry, with investments in over 45 companies in 15
          different countries, including market leaders such as Circle, Ledger, and Ripple.
          We also partner with Grayscale Investments (the largest digital currency asset management
          firm and the manager of 10 products, including the Bitcoin Investment Trust (symbol: GBTC)).
          At Swift Mine Pro we deliver investment and trading services using 10 markets trading platforms.
          Some of our trading services are: contracts for difference (CFD), precious metals trading,
          futures (agriculture, commodities, energy, etc.) and indices (stock index) trading.
        </p>

        <h2 className="title mt-3">
          Why Choose Us
        </h2>
        <p>
          Building an investment plan and an optimal asset allocation strategy to meet
          your unique needs requires careful consideration and often, outside expertise.
          Our Professional Investment Financial Advisors are committed to helping you with
          this process, allowing you to spend more time on the activities you truly enjoy.
          Our Financial Advisors take a holistic wealth management approach to carefully
          understand your overall financial situation, unique needs and goals, and deliver
          an optimal investment solution to meet them. Our breadth of offerings, intellectual
          capital and investment expertise empower our Financial Advisors and experts to
          partner with you to create a custom asset allocation and work with you to adapt
          your portfolio to the changing market environment and personal life events.
          Ultimately, allowing you more time to focus on what matters most.
        </p>
        <p>
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem
          accusantium doloremque laudantium, aperiam ipsquae ab illo inventore
          veritatis et quasi architecto beatae vitae dicta sunt explicabo
        </p>
        <div className="bd-approach-wrap">
          <div className="row align-items-center">
            <div className="col-46">
              <div className="bd-approach-content">
                <h4 className="title">Our Approach</h4>
                <p>
                  We're investing in different asset classes that are not in traditional benchmarks.
                  A good example is what are called busted convertible bonds, which are bonds whose
                  underlying stock trades well below the price at which the bond would convert to
                  stock. Having the ability to invest in a wide variety of assets lets you better
                  balance the income you get from bonds and dividends against the amount of risk
                  you're taking to earn that income.”
                </p>
              </div>
            </div>

            <div className="col-54">
              <div className="bd-approach-img">
                <img src={blogDetails01} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="bd-technology-content">
          <h2 className="title">Our Business Approach Roadmap?</h2>
          <p>
            Our ability to manage risk and look for opportunities in a wide variety
            of places matters because market conditions constantly change and the
            investments that deliver the highest returns today may not be the ones
            that do so next month or next year. We think it is crucial to ignore the
            emotions associated with past performance and, rather, to view each investment
            on its future merit. This requires lateral thinking and the willingness to adopt
            a contrarian point of view as both markets and company management teams have a
            propensity for hubris in the good times and unjustified pessimism when times are hard.
            Our contrarian investment and disruptive asset approach is grounded in the simple
            observation that people like to belong to a group or to feel part of something bigger.
            Humans have evolved to feel uncomfortable outwith the mainstream because, in more arduous
            times, a coordinated group stood a better chance of survival.
          </p>
        </div>

        <div className="blog-details-img">
          <div className="row">
            <div className="col-md-6">
              <img src={blogDetails02} alt="" />
            </div>
            <div className="col-md-6">
              <img src={blogDetails03} alt="" />
            </div>
          </div>
        </div>

        <div style={{width: '90vw'}}>
          <TeamOne />
        </div>




        <div className="blog-details-bottom">
          <div className="blog-details-tags">
            <ul>
              <li className="tag-title">Tag</li>
              <li>
                <Link to={"/services"}>Business</Link>
              </li>
              <li>
                <Link to={"/services"}>Investment</Link>
              </li>
              <li>
                <Link to={"/services"}>apps</Link>
              </li>
              <li>
                <Link to={"/services"}>Finance</Link>
              </li>
            </ul>
          </div>

          <div className="blog-details-social">
            <ul>
              <li className="social-title">Share</li>
              <li>
                <a href="#">
                  <i className="fab fa-linkedin"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-pinterest"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-facebook"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
